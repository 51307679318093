.slider {
    position: relative;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .image {
    width: 100%;
    /* height: 600px; */
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  .hero-div {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("https://images.pexels.com/photos/1154198/pexels-photo-1154198.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    min-height: 90vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100vw;
    overflow-x: hidden;
    /* border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%; */
    border-bottom-left-radius: 100% 250px;
		border-bottom-right-radius: 100% 250px;
		/* width: calc(100% + 100px) ; */
		margin-left: -50px;
  }
  .hero-div:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 340px;
    height: 80px;
    background-color: white;
    right: -5px;
    top: 40px;
  }
  .hero-div:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 300px;
    height: 70px;
    background-color: #e0efe3;
    left: 0;
    top: 27px;
  }
  .title-and-quote {
    width: 80%;
    margin: auto;
  }
  .title-and-quote > h1 {
    font-size: 2.5rem;
    font-weight: 100;
  }
  .title-and-quote > p {
    font-size: 1.5rem;
    color: lightgrey;
  }
  .home-page-shop-button {
    border-radius: 5px;
    cursor: pointer;
    background: var(--primary-green-color);
    border: 2px solid var(--primary-green-color);
    color: white;
    padding: 0.75rem 1.25rem;
    font-size: 1.25rem;
  }
  .home-page-shop-button * {
    color: white;
    text-decoration: none;
  }
  .button:disabled:hover {
    opacity: 0.4;
  }
  .button:focus {
    box-shadow: 0 0 0 5px #a7f3d0;
    outline: none;
  }
  a {
    text-decoration: none;
  }
  .button:hover {
    background: var(--primary-green-color);
    opacity: 0.9;
  }
  
  @media only screen and (min-width: 600px) {
    .hero-div {
    }
  }
  